<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullname || userData.username }}
        </p>
        <span class="user-status">{{ userData.jobTitle }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!--<b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profil</span>
    </b-dropdown-item>

    <b-dropdown-divider />-->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout(false)">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Quitter</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  isValidToken,
} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  beforeCreate: function () {
    isValidToken().then((Q) => {
        console.log('SESSION', Q)
        if (!Q) {
          this.logout(true)
        }
      }).catch((err) => {
        this.logout(true)
      });
  },
  methods: {
    logout(auto = false) {
      // Remove userData from localStorage
      this.$coddyger.emptyLocalStorage()

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      if(auto === true) {
        this.$router.push({ name: "auth-login", query: {ref: 'invalid-session'} });
      } else {
        this.$router.push({ name: "auth-login" });
      }
    },

  },
};
</script>
