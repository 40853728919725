export default [
  {
    header: 'Modules',
  },
  {
    title: 'Espace Medical',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Médecins',
        route: 'apps-medical-doctor',
        icon: 'ActivityIcon',
      },
      {
        title: 'Rendez-vous',
        route: 'apps-medical-rendezvous',
        icon: 'ActivityIcon',
      },
      {
        title: 'Frais et Soins',
        route: 'apps-medical-produit',
        icon: 'ActivityIcon',
      },
      {
        title: 'Prog. VSA & VMS',
        icon: 'ActivityIcon',
        children: [
          {
            title: '-Programme VSA',
            route: 'apps-medical-vsa',
          },
          {
            title: '-Programme VMS',
            route: 'apps-medical-vms',
          },
          {
            title: '-Exploitations',
            route: 'apps-medical-vsavms-direction',
          },
        ]
      },
      {
        title: 'Centres',
        route: 'apps-medical-centre',
        icon: 'ActivityIcon',
        children: [
          {
            title: '-Enregistrer',
            route: 'apps-medical-centre-save',
            icon: 'ActivityIcon',
          },
          {
            title: '-Pharmacies Agrées',
            route: 'apps-medical-pharmacie-agree',
            icon: 'ActivityIcon',
          },
          {
            title: '-Pharmacies de garde',
            route: 'apps-medical-pharmacie-garde',
            icon: 'ActivityIcon',
          },
          {
            title: '-Cliniques Agrées',
            route: 'apps-medical-centre-agree',
            icon: 'ActivityIcon',
          },
          {
            title: '-Cliniques et Hôpitaux',
            route: 'apps-medical-centre',
            icon: 'ActivityIcon',
          },
          {
            title: '-Infirmeries CIE',
            route: 'apps-medical-centre-infirmerie',
            icon: 'ActivityIcon',
          },
        ]
      },
    ]
  },
  {
    title: 'Sécurité',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Astreintes',
        icon: 'ShieldIcon',
        children: [
          {
            title: '-Liste',
            route: 'apps-securite-astreinte',
          },
          {
            title: '-Collaborateurs',
            route: 'apps-securite-astreinte-user',
          },
        ]
      },
      {
        title: 'Rendez-vous',
        route: 'apps-securite-rendezvous',
        icon: 'ShieldIcon',
      },
      {
        title: 'Alertes',
        route: 'apps-securite-alert',
        icon: 'ShieldIcon',
      },
      {
        title: 'Numéros Utiles',
        route: 'apps-securite-numero',
        icon: 'ShieldIcon',
      },
    ]
  },
  {
    title: 'Communication',
    route: 'apps-post-communication',
    icon: 'CastIcon',
  },
  {
    title: 'Charte',
    route: 'apps-post-conformite',
    icon: 'LayersIcon',
  },
  {
    title: 'Politique & Stratégie',
    route: 'apps-post-politique',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Mutuelle FCP',
    route: 'apps-post-mutuelle',
    icon: 'ApertureIcon',
  },
  {
    title: 'Demarche RSE/QSE/DEV Durable',
    route: 'apps-post-demarche',
    icon: 'PieChartIcon',
  },
  {
    title: 'Cadre Institutionnel',
    route: 'apps-post-cadre-institutionnel',
    icon: 'LayoutIcon',
  },
  {
    title: 'Espace RH',
    route: 'apps-post-rh',
    icon: 'ColumnsIcon',
  },
  {
    title: 'Partenaires Sociaux',
    route: 'apps-post-partenaire-sociaux',
    icon: 'SunriseIcon',
  },
]
